/**
 * 以下是 routes[index].meta 属性介绍
 * title: 导航展示标题
 * iconfont: 导航左侧图标
 * isShow: 是否是需要显示的导航，有该属性并且为true，才可以在页面中显示
 * name: 区分展示哪个二级导航和面包屑导航有用到，需要定位到当前name一级导航下
 * rank: 账号权限，对应当前权限的账号可以显示该导航（目前只支持一级导航）， 1: 总后台账号最高权限，2: 店铺账号，只能关注跟自己店铺有关的信息，不写的话会展示所有isShow为true的一级导航
 */

import store from '../store';

export default {
    routes: [
        
        // 店铺管理
        {
            path: '/store',
            component: () => import('../views/store'),
            meta: {
                title: '店铺管理',
                iconfont: 'ios-arrow-forward',
                isShow: true,
                name: 'Store',
                rank: [1]
            },
            children: [
                {
                    path: '/',
                    redirect: 'storeList'
                },
                // 店铺列表
                {
                    path: 'storeList',
                    name: 'StoreList',
                    component: () => import('../views/store/storeList'),
                    meta: {
                        name: '店铺列表',
                        isShow: true
                    }
                },
                // 添加店铺
                {
                    path: 'addStore',
                    name: 'AddStore',
                    component: () => import('../views/store/addStore'),
                    meta: {
                        name: '添加店铺',
                        isShow: false
                    }
                }
            ]
        },

        // 店铺类型
        {
            path: '/storeType',
            component: () => import('../views/storeType'),
            meta: {
                title: '导航分类',
                iconfont: 'ios-arrow-forward',
                isShow: true,
                name: 'StoreType',
                rank: [1]
            },
            children: [
                {
                    path: '/',
                    redirect: 'typeList'
                },
                // 分类设置
                // {
                //     path: 'setupType',
                //     name: 'SetupType',
                //     component: () => import('../views/storeType/setupType'),
                //     meta: {
                //         name: '分类设置'
                //     }
                // },

                // 类型管理
                {
                    path: 'typeList',
                    name: 'TypeList',
                    component: () => import('../views/storeType/typeList'),
                    meta: {
                        name: '一级导航',
                        isShow: true
                    }
                },

                // 类型添加
                {
                    path: 'addType',
                    name: 'AddType',
                    component: () => import('../views/storeType/addType'),
                    meta: {
                        name: '添加一级导航',
                        isShow: false
                    }
                },

                // 二级导航
                {
                    path: 'secNav',
                    name: 'SecNav',
                    component: () => import('../views/storeType/secNav'),
                    meta: {
                        name: '二级导航',
                        isShow: true
                    }
                },

                // 类型添加
                {
                    path: 'addSecNav',
                    name: 'AddSecNav',
                    component: () => import('../views/storeType/addSecNav'),
                    meta: {
                        name: '添加二级导航',
                        isShow: false
                    }
                }
            ]
        },

        // 店铺设置
        {
            path: '/storeSetup',
            component: ()=> import('../views/storeSetup'),
            meta: {
                title: '店铺管理',
                iconfont: 'ios-arrow-forward',
                isShow: true,
                name: 'StoreSetup',
                rank: [2]
            },
            children: [
                {
                    path: '/',
                    redirect: 'storeHome'
                },
                {
                    path: 'storeHome',
                    name: 'StoreHome',
                    component: () => import('../views/storeSetup/storeHome'),
                    meta: {
                        name: '数据概况',
                        isShow: true
                    }
                },
                {
                    path: 'storeInfo',
                    name: 'StoreInfo',
                    component: () => import('../views/storeSetup/storeInfo'),
                    meta: {
                        name: '店铺信息',
                        isShow: true
                    }
                },
                {
                    path: 'accountSetup',
                    name: 'AccountSetup',
                    component: () => import('../views/storeSetup/accountSetup'),
                    meta: {
                        name: '账户设置',
                        isShow: true
                    }
                }
            ]
        },
        
        // 订单管理
        {
            path: '/order',
            component: () => import('../views/order'),
            meta: {
                title: '订单管理',
                iconfont: 'ios-arrow-forward',
                isShow: true,
                name: 'Order',
                rank: [1, 2]
            },
            children: [
                {
                    path: '/',
                    redirect: 'whole'
                },
                {
                    path: 'whole',
                    name: 'whole',
                    component: () => import('../views/order/whole'),
                    meta: {
                        name: '全部订单',
                        isShow: true
                    }
                },
                {
                    path: 'inStore',
                    name: 'InStore',
                    component: () => import('../views/order/inStore'),
                    meta: {
                        name: '店内订单',
                        isShow: true
                    }
                },
                {
                    path: 'goodsOrder',
                    name: 'GoodsOrder',
                    component: () => import('../views/order/goodsOrder'),
                    meta: {
                        name: '商品订单',
                        isShow: true
                    }
                }
            ]
        },

        // 总后台商品管理
        {
            path: '/goods',
            component: () => import('../views/goods'),
            meta: {
                title: '商品管理',
                iconfont: 'ios-arrow-forward',
                isShow: true,
                name: 'Goods',
                rank: [1]
            },
            children: [
                {
                    path: '/',
                    redirect: 'goodsList'
                },
                {
                    path: 'goodsList',
                    name: 'GoodsList',
                    component: () => import('../views/goods/goodsList'),
                    meta: {
                        name: '商品审核',
                        isShow: true
                    }
                },
                // {
                //     path: 'goodsClass',
                //     name: 'GoodsClass',
                //     component: () => import('../views/goods/goodsClass'),
                //     meta: {
                //         name: '商品分类',
                //         isShow: true
                //     }
                // },
                // {
                //     path: 'addClass',
                //     name: 'AddClass',
                //     component: () => import('../views/goods/addClass'),
                //     meta: {
                //         name: '添加/编辑分类',
                //         isShow: false
                //     }
                // }
            ]
        },

        // 店铺商品管理
        {
            path: '/storeGoods',
            component: () => import('../views/storeGoods'),
            meta: {
                title: '商品管理',
                iconfont: 'ios-arrow-forward',
                isShow: true,
                name: 'StoreGoods',
                rank: [2]
            },
            children: [
                {
                    path: '/',
                    redirect: 'storeGoodsList'
                },
                {
                    path: 'storeGoodsList',
                    name: 'StoreGoodsList',
                    component: () => import('../views/storeGoods/storeGoodsList'),
                    meta: {
                        name: '商品列表',
                        isShow: true
                    }
                },
                {
                    path: 'addStoreGoods',
                    name: 'AddStoreGoods',
                    component: () => import('../views/storeGoods/addStoreGoods'),
                    meta: {
                        name: '添加/编辑商品',
                        isShow: false
                    }
                }
            ]
        },

        // 会员管理
        {
            path: '/vip',
            component: () => import('../views/vip'),
            meta: {
                title: '用户管理',
                iconfont: 'ios-arrow-forward',
                isShow: true,
                name: 'Vip',
                rank: [1]
            },
            children: [
                {
                    path: '/',
                    redirect: 'vipList'
                },
                {
                    path: 'vipList',
                    name: 'VipList',
                    component: () => import('../views/vip/vipList'),
                    meta: {
                        name: '用户列表',
                        isShow: true
                    }
                },
                {
                    path: 'vipOrder',
                    name: 'VipOrder',
                    component: () => import('../views/vip/vipOrder'),
                    meta: {
                        name: '用户订单',
                        isShow: false
                    }
                }
            ]
        },

        // 广告管理
        {
            path: '/ad',
            component: ()=> import('../views/ad'),
            meta: {
                title: '广告管理',
                iconfont: 'ios-arrow-forward',
                isShow: true,
                name: 'Ad',
                rank: [1]
            },
            children: [
                {
                    path: '/',
                    redirect: 'adList'
                },
                {
                    path: 'adList',
                    name: 'AdList',
                    component: () => import('../views/ad/adList'),
                    meta: {
                        name: '广告列表',
                        isShow: true
                    }
                },
                {
                    path: 'addAd',
                    name: 'AddAd',
                    component: () => import('../views/ad/addAd'),
                    meta: {
                        name: '添加广告',
                        isShow: false
                    }
                }
            ]
        },

        // 抽奖设置
        {
            path: '/coupon',
            component: () => import('../views/coupon'),
            meta: {
                title: '优惠券管理',
                iconfont: 'ios-arrow-forward',
                isShow: true,
                name: 'Coupon',
                rank: [1]
            },
            children: [
                {
                    path: '/',
                    redirect: 'couponInfo'
                },

                // 店铺列表
                {
                    path: 'couponInfo',
                    name: 'CouponInfo',
                    component: () => import('../views/coupon/couponInfo'),
                    meta: {
                        name: '优惠券设置',
                        isShow: true
                    }
                },
                {
                    path: 'couponList',
                    name: 'CouponList',
                    component: () => import('../views/coupon/couponList'),
                    meta: {
                        name: '优惠券列表',
                        isShow: true
                    }
                }
            ]
        },

        // 店铺抽奖设置
        {
            path: '/couponStore',
            component: () => import('../views/couponStore'),
            meta: {
                title: '优惠券管理',
                iconfont: 'ios-arrow-forward',
                isShow: true,
                name: 'CouponStore',
                rank: [2]
            },
            children: [
                {
                    path: '/',
                    redirect: 'couponStoreSetup'
                },
                {
                    path: 'couponStoreSetup',
                    name: 'CouponStoreSetup',
                    component: () => import('../views/couponStore/couponStoreSetup'),
                    meta: {
                        name: '优惠券设置',
                        isShow: true
                    }
                },
                {
                    path: 'couponStoreList',
                    name: 'CouponStoreList',
                    component: () => import('../views/couponStore/couponStoreList'),
                    meta: {
                        name: '优惠券列表',
                        isShow: true
                    }
                }
            ]
        },

        // 团长管理
        {
            path: '/tuan',
            component: () => import('../views/tuan'),
            meta: {
                title: '团长管理',
                iconfont: 'ios-arrow-forward',
                isShow: true,
                name: 'Tuan',
                rank: [1]
            },
            children: [
                {
                    path: '/',
                    redirect: 'tuanList'
                },
                {
                    path: 'tuanList',
                    name: 'TuanList',
                    component: () => import('../views/tuan/tuanList'),
                    meta: {
                        name: '团长列表',
                        isShow: true
                    }
                },
                {
                    path: 'tuanDetail',
                    name: 'TuanDetail',
                    component: () => import('../views/tuan/tuanDetail'),
                    meta: {
                        name: '团长概况',
                        isShow: false
                    }
                },
                {
                    path: 'tuanPoster',
                    name: 'TuanPoster',
                    component: () => import('../views/tuan/tuanPoster'),
                    meta: {
                        name: '团长海报',
                        isShow: true
                    }
                },
                {
                    path: 'yuanDetail',
                    name: 'YuanDetail',
                    component: () => import('../views/tuan/yuanDetail'),
                    meta: {
                        name: '团员概况',
                        isShow: false
                    }
                }
            ]
        },

        // 推荐员管理
        {
            path: '/tuijianyuan',
            component: () => import('../views/tuijianyuan'),
            meta: {
                title: '推荐员管理',
                iconfont: 'ios-arrow-forward',
                isShow: true,
                name: 'Tuijianyuan',
                rank: [1]
            },
            children: [
                {
                    path: '/',
                    redirect: 'tuijianyuanList'
                },
                {
                    path: 'tuijianyuanList',
                    name: 'TuijianyuanList',
                    component: () => import('../views/tuijianyuan/tuijianyuanList'),
                    meta: {
                        name: '推荐员列表',
                        isShow: true
                    }
                },
                {
                    path: 'addTuijianyuan',
                    name: 'AddTuijianyuan',
                    component: () => import('../views/tuijianyuan/addTuijianyuan'),
                    meta: {
                        name: '添加推荐员',
                        isShow: false
                    }
                }
            ]
        },

        // 资金管理
        {
            path: '/capital',
            component: () => import('../views/capital'),
            meta: {
                title: '资金管理',
                iconfont: 'ios-arrow-forward',
                isShow: true,
                name: 'Capital',
                rank: [1, 2]
            },
            children: [
                {
                    path: '/',
                    redirect: 'cashier'
                },
                {
                    path: 'cashier',
                    name: 'Cashier',
                    component: () => import('../views/capital/cashier'),
                    meta: {
                        name: '收银资金',
                        isShow: true
                    }
                },
                // {
                //     path: 'inStoreCapital',
                //     name: 'InStoreCapital',
                //     component: () => import('../views/capital/inStoreCapital'),
                //     meta: {
                //         name: '店内资金',
                //         isShow: true
                //     }
                // },
                // {
                //     path: 'goodsCapital',
                //     name: 'GoodsCapital',
                //     component: () => import('../views/capital/goodsCapital'),
                //     meta: {
                //         name: '商品资金',
                //         isShow: true
                //     }
                // },
                {
                    path: 'tuan',
                    name: 'Tuan',
                    component: () => import('../views/capital/tuan'),
                    meta: {
                        name: '团长资金',
                        isShow: true,
                        rank: [1]
                    }
                },
                {
                    path: 'tuijianyuan',
                    name: 'Tuijianyuan',
                    component: () => import('../views/capital/tuijianyuan'),
                    meta: {
                        name: '推荐员资金',
                        isShow: true,
                        rank: [1]
                    }
                },
                {
                    path: 'tuijianyuanDetail',
                    name: 'TuijianyuanDetail',
                    component: () => import('../views/capital/tuijianyuanDetail'),
                    meta: {
                        name: '推荐员详情',
                        isShow: false,
                        rank: [1]
                    }
                },
                {
                    path: 'finance',
                    name: 'Finance',
                    component: () => import('../views/capital/finance'),
                    meta: {
                        name: '店铺提现统计',
                        isShow: true
                    }
                },
                {
                    path: 'cashoutTuan',
                    name: 'CashoutTuan',
                    component: () => import('../views/capital/cashoutTuan'),
                    meta: {
                        name: '团长提现统计',
                        isShow: true,
                        rank: [1]
                    }
                }
            ]
        },

        // 评论管理
        {
            path: '/comment',
            component: () => import('../views/comment'),
            meta: {
                title: '评论管理',
                iconfont: 'ios-arrow-forward',
                isShow: true,
                name: 'Comment',
                rank: [2]
            },
            children: [
                {
                    path: '/',
                    redirect: 'notPass'
                },
                {
                    path: 'notPass',
                    name: 'NotPass',
                    component: () => import('../views/comment/notPass'),
                    meta: {
                        name: '未审核',
                        isShow: true
                    }
                },
                {
                    path: 'reviewed',
                    name: 'Reviewed',
                    component: () => import('../views/comment/reviewed'),
                    meta: {
                        name: '已过审',
                        isShow: true
                    }
                },
                {
                    path: 'unapprove',
                    name: 'Unapprove',
                    component: () => import('../views/comment/unapprove'),
                    meta: {
                        name: '未过审',
                        isShow: true
                    }
                }
            ]
        },

        // 投诉管理
        // {
        //     path: '/tousu',
        //     component: () => import('../views/tousu'),
        //     meta: {
        //         title: '投诉管理',
        //         iconfont: 'ios-arrow-forward',
        //         isShow: true,
        //         name: 'Tousu',
        //         rank: [2]
        //     },
        //     children: [
        //         {
        //             path: '/',
        //             redirect: 'tousuList'
        //         },
        //         {
        //             path: 'tousuList',
        //             name: 'TousuList',
        //             component: () => import('../views/tousu/tousuList'),
        //             meta: {
        //                 name: '投诉列表',
        //                 isShow: true
        //             }
        //         }
        //     ]
        // },

        // 审核管理
        // {
        //     path: '/auditsManagement',
        //     component: () => import('../views/auditsManagement'),
        //     meta: {
        //         title: '审核管理',
        //         iconfont: 'md-cart',
        //         isShow: true,
        //         name: 'AuditsManagement',
        //         rank: [1]
        //     },
        //     children: [
        //         {
        //             path: '/',
        //             redirect: 'cashoutAudits'
        //         },
        //         {
        //             path: 'cashoutAudits',
        //             name: 'CashoutAudits',
        //             component: () => import('../views/auditsManagement/cashoutAudits'),
        //             meta: {
        //                 name: '提现审核',
        //                 isShow: true
        //             }
        //         }
        //     ]
        // },

        // 账户管理
        {
            path: '/account',
            component: () => import('../views/account'),
            meta: {
                title: '账户管理',
                iconfont: 'md-contact',
                isShow: true,
                name: 'Account',
                rank: [1]
            },
            children: [
                {
                    path: '/',
                    redirect: 'accountList'
                },
                {
                    path: 'accountList',
                    name: 'AccountList',
                    component: () => import('../views/account/accountList'),
                    meta: {
                        name: '账户列表',
                        isShow: true
                    }
                }
            ]
        },

        // 系统设置
        {
            path: '/setup',
            component: () => import('../views/setup'),
            meta: {
                title: '系统管理',
                iconfont: 'md-settings',
                isShow: true,
                name: 'Setup',
                rank: [1]
            },
            children: [
                {
                    path: '/',
                    redirect: 'setupInfo'
                },
                {
                    path: 'setupInfo',
                    name: 'SetupInfo',
                    component: () => import('../views/setup/setupInfo'),
                    meta: {
                        name: '系统设置',
                        isShow: true
                    }
                },
                {
                    path: 'classStore',
                    name: 'ClassStore',
                    component: () => import('../views/setup/classStore'),
                    meta: {
                        name: '店铺抽成类型',
                        isShow: true
                    }
                },
                {
                    path: 'payPoster',
                    name: 'PayPoster',
                    component: () => import('../views/setup/payPoster'),
                    meta: {
                        name: '收款码海报',
                        isShow: true
                    }
                },
                {
                    path: 'share',
                    name: 'Share',
                    component: () => import('../views/setup/share'),
                    meta: {
                        name: '平台分享',
                        isShow: true
                    }
                },
                {
                    path: 'qianggouPoster',
                    name: 'GianggouPoster',
                    component: () => import('../views/setup/qianggouPoster'),
                    meta: {
                        name: '首页抢购图',
                        isShow: true
                    }
                },
                {
                    path: 'account',
                    name: 'Account',
                    component: () => import('../views/setup/account'),
                    meta: {
                        name: '账户设置',
                        isShow: true
                    }
                },
            ]
        },

        // 登陆
        {
            path: '/login',
            name: 'Login',
            component: () => import('../views/login'),
            meta: {
                isShow: false
            }
        },

        // 注册
        {
            path: '/adminRegister',
            name: 'AdminRegister',
            component: () => import('../views/register'),
            meta: {
                isShow: false
            }
        },
        {
            path: '*',
            redirect: () => {
                const account = store.state.account;

                if (!account) return;

                if (+account.rank === 1) {
                    return '/store';
                }
                else {
                    return '/storeSetup';
                }
            },
            meta: {
                isShow: false
            }
        }
    ]
}
